/* eslint-disable prettier/prettier */
/* eslint-disable no-else-return */
/* eslint-disable */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';
import { bake_cookie } from 'sfcookies';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';
import IntlMessages from '../../helpers/IntlMessages';
import { adminRoot, frontLink, backLink} from '../../constants/defaultValues';
import { setCurrentUser, setEnterpriseForm, getCurrentLanguage } from '../../helpers/Utils';
import { lang } from 'moment';

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (mail, pass, history) =>
  // eslint-disable-next-line no-return-await
  await fetch(`${backLink}/auth`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      EMAIL: mail,
      PASSWORD: pass,
    }),
  })
    .then(async (res) => {
      // console.log(res);
      if (res.status === 200) {
        const r = await res.json();
        // console.log( await r);
        const decode = jwt_decode(r.accessToken);
        localStorage.setItem('tokenExp', decode.exp);
        localStorage.setItem('accessToken', r.accessToken);
        bake_cookie('refreshToken', r.refreshToken);
        const result = await fetch(
          `${backLink}/users/${decode.userId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              // prettier-ignore
              'Authorization': `Bearer ${r.accessToken}`,
            },
          }
        );
        localStorage.setItem('unlog', "false");
        const resultBis = await result.json();
        return resultBis;
      } else if (res.status === 403) {
        const r = await res.json();
        localStorage.setItem('confirmToken', r.confirmToken);
        localStorage.setItem('confirmEmail', mail);
        history.push('/confirm')
      }
      const tmp = await res.json();
      console.log(tmp)
      return tmp;
    })
    .catch((error) => {console.log(error)});

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password, history);
    if (loginUser && loginUser.ID && loginUser.NAME) {
      const item = {
        id: loginUser.ID,
        title: loginUser.NAME,
        email : loginUser.EMAIL,
        img: '/assets/img/profiles/team.jpg',
        date: loginUser.DATE_INSCRIT,
        role: parseInt(
          loginUser.PREMIUM != null ? loginUser.PREMIUM : '0',
          10
        ),
        CS_ID: loginUser.CS_ID_BTOB ? loginUser.CS_ID_BTOB : loginUser.CS_ID,
        emailValidation: loginUser.VALIDE,
        logo: loginUser.PATH_LOGO,
        btb: loginUser.BTOB,
        btbid: loginUser.ID_BTOB,
        admin: loginUser.ADMIN,
        pairing: loginUser.SPONSORED,
        pairing_code: loginUser.ACCOUNT_SPONSOR_CODE,
        pairing_number: loginUser.COUNTER_SPONSOR,
      };
      setCurrentUser(item);
      localStorage.setItem('unlog', "false");
      yield put(loginUserSuccess(item));
      history.push(adminRoot);
    } else {
      console.log(loginUser)
      yield put(loginUserError(<IntlMessages id="user.login-error-message" />));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (mail, pass, name, code,) =>
  // eslint-disable-next-line no-return-await
  await fetch(`${backLink}/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      NAME: name,
      EMAIL: mail,
      PASSWORD: pass,
      SPONSOR_CODE: code,
      LOCALE: localStorage.getItem('lang'),
    }),
  })
    .then(async (res) => {
      // console.log(res);
      const r = await res.json();
      console.log(r)
      if (res.status === 200) {
        localStorage.setItem('confirmToken', r.confirmToken);
        localStorage.setItem('confirmEmail', mail);
        localStorage.setItem('pairingcode', code);
        window.location.href = `${frontLink}/confirm`;
        return res.status;
      }
      return r;
    })
    .catch(async (error) =>  error);

function* registerWithEmailPassword({ payload }) {
  const { email, password, name, code} = payload.user;
  try {
    console.log(localStorage.getItem('lang'));
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password,
      name,
      code,
    );
    // console.log(registerUser);TVBNC4U7IE
    if (registerUser === 200 ) {
      localStorage.setItem('unlog', "false");
      window.location.href = `${frontLink}/confirm`;
    } else if(registerUser === "Sponsor does not exists") {
      yield put(registerUserError("Ce code de parainage n'existe pas"));
    } else {
      console.log(registerUser);
      yield put(registerUserError(<IntlMessages id="user.register-error-message" />));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async () => {
  window.location.href = `${frontLink}/login`
};

function* logout() {
  setCurrentUser('');
  setEnterpriseForm('');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('current_user');
  localStorage.clear();
  localStorage.removeItem('refreshToken');
  yield call(logoutAsync);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (mail) => {
  // eslint-disable-next-line no-return-await
  return await fetch(`${backLink}/auth/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      EMAIL: mail,
    }),
  })
    .then(async (r) => {
      // console.log(r);
      const res = await r.json();
      // console.log('success');
      return res;
    })
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // eslint-disable-next-line no-return-await
  return await fetch(`${backLink}/users/reset-password`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      // prettier-ignore
      'Authorization': `Bearer ${window.location.search.substr(7, window.location.search.length)}`,
    },
    body: JSON.stringify({
      PASSWORD: newPassword,
    }),
  })
    .then(async (r) => {
      // console.log(r);
      if (r.status === 200) {
        // console.log('success');
        return 'success';
      }
      return 'error';
    })
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  // console.log(window.location.search.substr(7, window.location.search.length));
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (resetPasswordStatus) {
      yield put(resetPasswordSuccess(<IntlMessages id="user.reset-message" />));
    } else {
      yield put(resetPasswordError(<IntlMessages id="user.reset-error-message" />));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
